<template>
  <div>
    <div class="container-sm-lot pl-1 pr-1 ctx-rex mt-2">
      <div class="ic-back">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer"
          @click="$router.go(-1)"
        >
      </div>
      <div class="cards ctx-card w-100 my-2">
        <div class="card-body">
          <div>
            <h3 class="font-weight-bolder text-primary mb-0 text-center">
              ลืมรหัสผ่าน
            </h3>

            <p class="mb-0 text-primary text-center">
              กรุณากรอกหมาย E-mail
            </p>

            <div class="mt-1">
              <input
                id="username"
                v-model="email"
                type="text"
                class="form-custom-new w-100"
                placeholder="กรุณากรอก E-mail"
              >
            </div>

            <div class="text-center">
              <button
                class="btn-forgot mt-2 mb-1"
                @click="sendVerify()"
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
// import useJwt from '@/auth/jwt/useJwt'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      email: '',
      password: '',
      role: null,
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    sendVerify() {
      const data = {
        email: this.email,
      }
      this.$http
        .post('/lotto-suvarn/ResetPassword', data)
        // eslint-disable-next-line no-unused-vars
        .then(ress => {
          // console.log(ress.data)
          this.Success(ress.data)
          this.email = ''
        })
        .catch(err => {
          // console.log(err.response.data.message)
          this.SwalError(err.response.data.message)
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">Success</h3>',
        text: mes,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        timer: 2000,
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'warning',
        title: '<h3 style="color: #141414">Warning !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped></style>
